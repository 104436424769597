import {
  type CountryCode,
  type Property,
  Language,
  PropertyMarketingType,
  PropertyType,
} from '@ev/search-modules-api';
import { type ParseKeys, type TFunction } from 'i18next';

import {
  FINANCING_ENABLED_COUNTRIES,
  SEO_INDEXED_COUNTRIES,
} from '@/components/common/Search/constants';
import { type TransformedSurfaceFieldsType } from '@/components/common/Search/hooks/useConvertedSurfaceFields';
import { EU_COUNTRIES } from '@/core/const/energyClasses';
import { mapLanguageTranslation } from '@/core/const/settingsConfig';
import { Country } from '@/core/enums/country';
import { type AgentShopDetails } from '@/core/types/property.types';
import { formatIntlList } from '@/core/utils/stringUtils';

export const DEFAULT_IMAGE =
  'https://uploadcare.engelvoelkers.com/537053a4-4e12-44c5-af68-99710d3150be/';

export const DEFAULT_IMAGE_NEW =
  'https://uploadcare.engelvoelkers.com/4a6e9b16-f2a8-4afc-ba26-a7d0cbcb1dbb/';

export const NO_IMAGES_BACKGROUND =
  'https://uploadcare.engelvoelkers.com/a2851646-872c-4dca-a1bd-4631ae5b1313/-/format/webp/-/stretch/off/-/progressive/yes/';

export const getNumberOfDays = (start: Date, end: Date): number => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};

export const getParsedShopName = (shopName: string): string => {
  const regexName = /Engel & Völkers/g;
  const replacedShopName = shopName.replace(regexName, '');

  return replacedShopName;
};

export const getOfficeNameForLabels = (
  officeName?: string,
  shopName?: string
) => {
  return officeName
    ? officeName
    : shopName
    ? getParsedShopName(shopName)
    : 'Engel & Völkers';
};

export const getPropertyAvailableLanguageMessage = (
  t: TFunction<'common'>,
  language: Language,
  availableLanguages: Array<string>
): string => {
  const availableLanguagesLabel = availableLanguages
    .filter((lang) => lang in Language)
    .map((lang) => {
      return t(
        `${
          mapLanguageTranslation[lang.toLowerCase() as Language]
        }` as ParseKeys<'common'>
      );
    });
  const languages = formatIntlList(availableLanguagesLabel, language);

  return t('search.expose.label.availableLanguagesBanner', {
    languages,
  });
};

export const getPropertyAgentShopDetails = (
  property: Property
): AgentShopDetails => {
  return {
    agentName: property.agentName,
    officeName: property.officeName,
    shopMasterLicencePartner: property.shopMasterLicencePartner,
    imprint: property.profile?.imprint,
    shopWebURL: property.shopWebURL,
    agentRegistrationNumber: property.agentRegistrationNumber,
    shopRegistrationNumber: property.shopRegistrationNumber,
    shopPermitNumber: property.shopPermitNumber,
    shopAddressStreet: property.shopAddressStreet,
    shopAddressPostalCode: property.shopAddressPostalCode,
    shopAddressCity: property.shopAddressCity,
    shopAddressCountry: property.shopAddressCountry,
    shopName: property.shopName,
    shopPhoneNumber: property.shopPhoneNumber,
    agentPhoneNumber:
      property.countryAlpha2 === Country.ZA ? property.agentPhoneNumber : '',
    displayId: property.displayID || '',
  };
};

export const hasExtraInfo = (propertyData: Property): boolean => {
  return Boolean(
    propertyData.rooms ||
      propertyData.bedrooms ||
      propertyData.bathrooms ||
      propertyData.areas?.['sqmt']?.livingSurface ||
      propertyData.areas?.['sqmt']?.totalSurface ||
      propertyData.areas?.['sqmt']?.officeSurface ||
      propertyData.areas?.['sqmt']?.salesSurface ||
      propertyData.areas?.['sqmt']?.storageSurface ||
      propertyData.areas?.['sqmt']?.terraceSurface ||
      propertyData.areas?.['sqmt']?.dividableFrom ||
      propertyData.areas?.['sqmt']?.plotSurface ||
      propertyData.parkingBays ||
      propertyData.condition ||
      propertyData.parkingBays ||
      propertyData.objectSubType ||
      propertyData.constructionYear ||
      propertyData.objectType ||
      propertyData.garages ||
      propertyData.flooring ||
      propertyData.floors
  );
};

export const hasFeatures = (propertyData: Property): boolean => {
  return Boolean(
    propertyData.hasAirConditioning ||
      propertyData.hasBalcony ||
      propertyData.hasBasement ||
      propertyData.hasBuiltInKitchen ||
      propertyData.hasGarage ||
      propertyData.hasGarden ||
      propertyData.hasPatio ||
      propertyData.hasSeaOrLakeView ||
      propertyData.hasSecuritySystem ||
      propertyData.hasSwimmingPool ||
      propertyData.hasTennisCourt ||
      propertyData.hasTerrace ||
      propertyData.hasWaterfront ||
      (Array.isArray(propertyData.elevator) && propertyData.elevator.length) ||
      propertyData.elevator ||
      propertyData.petsAllowed ||
      propertyData.heatProtectionClass
  );
};

export const hasEnergyInfo = (propertyData: Property): boolean => {
  return Boolean(
    getEnergyCertificateTranslationKey(
      propertyData.objectType,
      propertyData.countryAlpha2,
      propertyData.energyCertAvailable
    ) ||
      (propertyData.epCode && propertyData.countryAlpha2 === Country.BE) ||
      (propertyData.co2Emission && propertyData.countryAlpha2 === Country.BE) ||
      propertyData.energyCertType ||
      propertyData.energyClassNormalized ||
      propertyData.energyEfficiencyFactor ||
      propertyData.finalEnergyConsumption ||
      propertyData.energyFinalDemand ||
      propertyData.heatingType ||
      propertyData.heatingMaterial ||
      propertyData.heatingConsumption ||
      propertyData.heatingFinalConsumption ||
      propertyData.co2EmissionRate ||
      propertyData.powerFinalConsumption
  );
};

export const getExposeUrl = (isDevelopment: boolean, id?: string): string => {
  return `/${isDevelopment ? 'projects' : 'exposes'}/${id}`;
};

const PLACEID_SEPARATOR = '.';

export const getLocalityPlaceId = (placeIds: string[]) => {
  const localityPlaceId = placeIds.find((placeId) =>
    placeId.includes('locality.')
  );
  if (!localityPlaceId) {
    // fallback to the highest administrative area in case there is no locality
    // Google Maps admin area levels go from 1 to 7, so a simple sort should do the trick to get the highest level
    const orderedAdminAreas = placeIds
      .filter((placeIds) => placeIds.includes('administrative_area_level_'))
      .sort()
      .reverse();
    const highestAdminArea = orderedAdminAreas[0];
    return highestAdminArea ? highestAdminArea.split(PLACEID_SEPARATOR)[1] : '';
  }
  return localityPlaceId.split(PLACEID_SEPARATOR)[1];
};

// for snowplow location context, we want to retrieve the place id of the "smallest" address component
export const getPlaceIdForLocationContext = (placeIds: string[]) => {
  const neighborhoodPlaceId = placeIds.find((placeId) =>
    placeId.includes('neighborhood')
  );
  if (neighborhoodPlaceId) {
    return neighborhoodPlaceId.split(PLACEID_SEPARATOR)[1];
  }
  // Google Maps sublocality levels go from 1 to 5, so a simple sort should do the trick to get the highest level
  const orderedSublocalities = placeIds
    .filter((placeIds) => placeIds.includes('sublocality_level'))
    .sort()
    .reverse();
  if (orderedSublocalities.length > 0) {
    return orderedSublocalities[0].split(PLACEID_SEPARATOR)[1];
  }
  return getLocalityPlaceId(placeIds);
};

export const getEnergyCertificateTranslationKey = (
  objectType: Property['objectType'],
  country: Property['countryAlpha2'],
  energyCertificateAvailable?: Property['energyCertAvailable']
) => {
  const showEnergyCertAvailability = Boolean(
    objectType !== PropertyType.land &&
      country &&
      EU_COUNTRIES.includes(country)
  );
  if (energyCertificateAvailable === undefined) {
    return '';
  }
  if (energyCertificateAvailable === 'yes') {
    return 'search.general.label.yes';
  }
  if (energyCertificateAvailable === 'no' && showEnergyCertAvailability) {
    return 'search.general.label.no';
  }
  if (energyCertificateAvailable === 'unknown' && showEnergyCertAvailability) {
    return 'search.expose.propertyDetails.energy.value.energyCertificateAvailable.unknown';
  }
  return '';
};

export const isIndexedProperty = (
  property: Property,
  language: Language,
  pageLanguages: string[],
  countryCode?: CountryCode
) =>
  Boolean(
    property.countryAlpha2 &&
      countryCode === property.countryAlpha2 &&
      SEO_INDEXED_COUNTRIES.has(property.countryAlpha2) &&
      property.language?.includes(language) &&
      language !== Language.ca &&
      pageLanguages.includes(language)
  ); // https://engelvoelkers.atlassian.net/browse/NS-5401

export const getCourtagePassusTitleTranslationKey = (property: Property) =>
  property.countryAlpha2 === Country.DE &&
  property.marketingType === PropertyMarketingType.rent
    ? 'search.expose.propertyDetails.label.courtagePassusRentalGermanyProperty'
    : 'search.expose.propertyDetails.label.courtagePassus';

export const hasFinancingSection = (property: Property) =>
  !!property.countryAlpha2 &&
  FINANCING_ENABLED_COUNTRIES.has(property.countryAlpha2) &&
  property.marketingType === PropertyMarketingType.sale;

export const getPropertyDetails = (
  property: Property,
  transformedSurfaceFields: TransformedSurfaceFieldsType,
  livingAreaLabel: ParseKeys<'common'>,
  showConstructionYear: boolean = false,
  t: TFunction<'common'>
) => {
  const propertyDetails = [
    {
      label: t(
        property.objectSubType
          ? 'search.expose.propertyDetails.label.propertySubtype'
          : 'search.searchbar.propertyType'
      ),
      content:
        property.objectSubType || property.objectType
          ? t(
              property.objectSubType
                ? (`search.searchbar.advFilter.propertySubType.${property.objectSubType}` as ParseKeys<'common'>)
                : (`search.searchbar.advFilter.propertyType.${property.objectType}` as ParseKeys<'common'>)
            )
          : '',
    },
    {
      label: t('search.expose.propertyDetails.label.condition'),
      content:
        property.condition &&
        t(
          `search.expose.propertyDetails.${property.condition}` as ParseKeys<'common'>
        ),
    },
    {
      label: t('search.expose.propertyDetails.label.rooms', {
        count: 2, // hardcode labels to plural
      }),
      content: property.rooms,
    },
    {
      label: t('search.expose.propertyDetails.label.bedrooms', {
        count: 2, // hardcode labels to plural
      }),
      content: property.bedrooms,
    },
    {
      label: t('search.expose.propertyDetails.label.bathrooms', {
        count: 2, // hardcode labels to plural
      }),
      content: property.bathrooms,
    },
    {
      label: t('search.expose.propertyDetails.label.floor'),
      content: property.floor,
    },
    {
      label: t('search.expose.propertyDetails.label.numberOfFloors'),
      content: property.floors,
    },
    {
      label: t('search.expose.propertyDetails.label.garage', {
        count: 2, // hardcode labels to plural
      }),
      content: property.garages,
    },
    {
      label: t('search.expose.propertyDetails.label.parkingBays'),
      content: property.parkingBays,
    },
    {
      label: t('search.expose.propertyDetails.label.totalSurface'),
      content: transformedSurfaceFields.totalSurface,
    },
    {
      label: t(livingAreaLabel),
      content: transformedSurfaceFields.livingSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.plotSurface'),
      content: transformedSurfaceFields.plotSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.shopSurface'),
      content: transformedSurfaceFields.shopSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.terraceSurface'),
      content: transformedSurfaceFields.terraceSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.officeSurface'),
      content: transformedSurfaceFields.officeSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.storageSurface'),
      content: transformedSurfaceFields.storageSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.salesSurface'),
      content: transformedSurfaceFields.salesSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.dividableFrom'),
      content: transformedSurfaceFields.dividableFrom,
    },
    {
      label: t('search.expose.propertyDetails.label.roiCurrent'),
      content: property.roiCurrent ? `${property.roiCurrent} %` : '',
    },
    {
      label: t('search.expose.propertyDetails.label.roiTarget'),
      content: property.roiTarget ? `${property.roiTarget} %` : '',
    },
    {
      label: t('search.expose.propertyDetails.label.flooring'),
      content: property.flooring
        ?.map((floor) =>
          t(
            `search.expose.propertyDetails.label.flooring.${floor}` as ParseKeys<'common'>
          )
        )
        .join(', '),
    },
    {
      label: t('search.expose.propertyDetails.label.constructionYear'),
      content: showConstructionYear ? property.constructionYear : null,
    },
  ];

  return propertyDetails;
};

export const shouldShowAutogenButton = (property: Property) =>
  property.autogenPageUrl &&
  [PropertyType.house, PropertyType.apartment, PropertyType.land].some(
    (propertyType) => propertyType === property.objectType
  );
